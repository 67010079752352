













































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { FundUserDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundProjectYearSummary",
  components: {
    PagedTableView,
    AbSelect,
  },
})
export default class FundProjectYearSummaryList extends Vue {
  typeList: any = [];
  fundProjectId = "";
  queryForm = {
    year: "",
  };
  detailId = 0;

  created() {
    this.fundProjectId = Number(this.$route.params.fundProjectId) + "";
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    if (this.fundProjectId) {
      params.projectId = this.fundProjectId;
    }
    return api.fundProjectYearSummaryService.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "fundProjectYearSummaryCreate",
      params: {
        projectId: this.fundProjectId,
      },
    });
  }

  // 查看详情
  jumpDetail(index: number, row: FundUserDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "fundProjectYearSummaryDetail",
      params: {
        id: row.id!.toString(),
        projectId: this.fundProjectId,
      },
    });
  }

  // 编辑
  handleEdit(index: number, row: FundUserDto) {
    this.$router.push({
      name: "fundProjectYearSummaryEdit",
      params: {
        id: row.id!.toString(),
        projectId: this.fundProjectId,
      },
    });
  }

  // 删除
  async handleDelete(index: number, row: FundUserDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.fundProjectYearSummaryService
        .delete({ id: row.id })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }
  private cancel() {
    this.$router.back();
  }
}
